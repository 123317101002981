import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import Footer from '../components/footer'
import facebooklogo from '../assets/images/icon/facebook.svg'
import linkedinlogo from '../assets/images/icon/linkedin.svg'
import knsicongrey from '../assets/images/icon/kns-icon-grey.svg'
import knsblue from '../assets/images/logo/kns-blue.svg'

import phone from '../assets/images/icon/phone.svg'
import mail from '../assets/images/icon/mail.svg'
import axios from 'axios'
import $ from 'jquery'
import LocalizedLink from '../LocalizedLink'

import styles from '../components/style.module.css'
import locales from '../constants/locales'

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    var location = this.props.location.pathname
    var array = location.split('/')
    var result = array[array.length - 1]
    if (result === 'cm') {
      result = 'platforms/cm'
    } else if (result === 'crm') {
      result = 'platforms/crm'
    } else if (result === 'fm') {
      result = 'platforms/fm'
    } else if (result === 'venuex') {
      result = 'platforms/venuex'
    }
    this.state = {
      tr: (
        <Link
          className={
            'en' === this.props.pageContext.locale ? styles.active : ''
          }
          key={'EN'}
          to={locales['en'].default ? `/${result}` : `/${result}`}
        >
          <span>{locales['en'].locale}</span>
        </Link>
      ),
      en: (
        <Link
          className={
            'tr' === this.props.pageContext.locale ? styles.active : ''
          }
          key={'TR'}
          to={
            locales['tr'].default ? '/' : `/${locales['tr'].path}/${location}`
          }
        >
          <span>{locales['tr'].locale}</span>
        </Link>
      ),
      hash: '',
      name: '',
      phone: '',
      from: '',
      message: '',
      agreement: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.sendmail = this.sendmail.bind(this)
  }

  componentDidMount() {
    if (window.location.pathname === '/contactPage') {
      $('.logo a img').attr('src', knsblue)
      $('.menu ul li a').css('color', '#1b437b')
      $('.header').css('background', '#ffffff')
      $('.language').css('border-color', '#1b437b')
      $('.language a span').css('color', '#1b437b')
      $('.other-page-social').show(100)
      $('#nav-toggle span').addClass('colorline')
      $('.numbers h1,.numbers h4').css('color', '#1b437b')
    }

    $('.mb-nav-btn').click(function() {
      $('.mobile-menu').fadeIn(200)
    })
    $('.close').click(function() {
      $('.mobile-menu').fadeOut(200)
    })

    $('.mobile-menu ul li a').click(function() {
      $('.mobile-menu').fadeOut(200)
    })
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
  async sendmail(e) {
    e.preventDefault()

    var formLocalizedTexts
    {
      this.props.pageContext.locale === 'en'
        ? (formLocalizedTexts = {
            cannot_be_blank: 'Please fill the empty fields.',
            email_not_valid: 'Please fill a valid mail address.',
            form_success: 'Thanks for your feedback.',
            checkbox_cannot_be_blank: 'Please fill the empty checkbox',
            error: 'Error',
            ok: 'OK',
          })
        : (formLocalizedTexts = {
            cannot_be_blank: 'Lütfen boş alanları doldurunuz.',
            email_not_valid: 'Lütfen geçerli bir mail adresi giriniz.',
            form_success: 'Bizimle iletişime geçtiğiniz için teşekkür ederiz.',
            checkbox_cannot_be_blank: "Lütfen boş checkbox'ı onaylayınız.",
            error: 'Hata',
            ok: 'Tamam',
          })
    }
    var $errorDisplayer = $('.error-displayer')
    $errorDisplayer.text('')

    if (!this.checkForm()) {
      $errorDisplayer.text(formLocalizedTexts['cannot_be_blank'])

      return
    }
    if (this.state.agreement != 'on') {
      $errorDisplayer.text(formLocalizedTexts['checkbox_cannot_be_blank'])

      return
    }
    if (!this.validateEmail(this.state.from)) {
      var $email = $("input[name='from']")
      $email.addClass('error')
      $errorDisplayer.text(formLocalizedTexts['email_not_valid'])

      return
    }
    if (!$errorDisplayer.text()) {
      this.clearErrors()
      this.clearForm()
    }
    $errorDisplayer.text(formLocalizedTexts['form_success'])
    if (process.env.NODE_ENV === 'development') {
      await axios
        .post('http://localhost:3001/send', {
          name: this.state.name,
          email: this.state.from,
          phone: this.state.phone,
          message: this.state.message,
        })
        .then(function(response) {
          console.log(response)
        })
        .catch(function(error) {
          console.log(error)
        })
    }
    if (process.env.NODE_ENV === 'production') {
      await axios
        .post('http://mailer.kns.com.tr/send', {
          name: this.state.name,
          email: this.state.from,
          phone: this.state.phone,
          message: this.state.message,
        })
        .then(function(response) {
          console.log(response)
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }
  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }
  clearForm() {
    this.setState({
      name: '',
      from: '',
      phone: '',
      message: '',
      agreement: false,
    })
  }

  clearErrors() {
    var $errorDisplayer = $('.error-displayer')
    $errorDisplayer.text('')
  }
  checkForm() {
    var $name = $("input[name='name']")
    var $phone = $("input[name='phone']")
    var $email = $("input[name='from']")
    var $message = $("textarea[name='message']")
    var error = false
    if (!this.state.name || this.state.name.length == 0) {
      error = true
      $name.addClass('error')
    }
    if (!this.state.phone || this.state.phone.length == 0) {
      error = true
      $phone.addClass('error')
    }
    if (!this.state.from || this.state.from.length == 0) {
      error = true
      $email.addClass('error')
    }
    if (!this.state.message || this.state.message.length == 0) {
      error = true
      $message.addClass('error')
    }
    return !error
  }

  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="other-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="mobile-menu">
            <div className="close">
              <img src={require('../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>

          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <section className="page">
            <div className="container">
              <div className="col-12">
                <figure className="kns-grey-icon">
                  <img src={knsicongrey} />
                </figure>

                <div className="page-prp">
                  <h1>
                    <FormattedMessage id="contact" />
                  </h1>
                  {this.props.pageContext.locale == 'tr' && (
                    <>
                      <p>
                        Create a remarkable visitor experience with the touch of
                        a sustainable digital marketing platform.
                      </p>
                    </>
                  )}
                  {this.props.pageContext.locale == 'en' && (
                    <>
                      <p>
                        Create a remarkable visitor experience with the touch of
                        a sustainable digital marketing platform.
                      </p>
                    </>
                  )}
                </div>

                <div className="contact-page">
                  <section className="maps">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1282.1512145789109!2d29.018507099397468!3d41.10320153303796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab5dcc90bb085%3A0x3bdcdabdc3beaa5c!2zxLBUw5wgQVJJIDggVEVLTk9LRU5U!5e0!3m2!1str!2str!4v1655451188980!5m2!1str!2str"
                      width="100%"
                      height="500"
                      frameBorder="0"
                      style={{ border: 0 }}
                      allowFullScreen
                    />
                    <div className="maps-prp">
                      <div className="circle-one" />
                      <div className="circle-two" />
                      <h1>
                      Reşitpaşa Mah. Katar Cad. İTÜ Teknokent, ARI 8 Binası No:2/11/2-B 34467 Sarıyer,<br/>İstanbul / TURKEY
                      </h1>
                      <article className="cnt-phone-mail">
                        <a href="tel:+90 212 296 28 71">
                          <img src={phone} />
                          <span>+90 212 296 28 71</span>
                        </a>
                        <a href="mailto:info@kns.com.tr">
                          <img src={mail} />
                          <span>info@kns.com.tr</span>
                        </a>
                      </article>
                      <a
                        href="https://www.google.com/maps?ll=41.0836348,28.9801844&z=16&t=m&hl=en-US&gl=TR&mapclient=embed&cid=15577628135581443071"
                        target="_blank"
                        className="see-google"
                      >
                        SEE ON GOOGLE MAPS
                      </a>
                    </div>
                  </section>
                  <div className="container">
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <p>
                          Since 2011, presenting more than 750+ products, KNS
                          has been building a bridge between physical and
                          digital world in 7 Countries, more than 150+ Shopping
                          Malls, Universities and City Hospitals.
                        </p>
                      </>
                    )}
                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <p>
                          Since 2011, presenting more than 750+ products, KNS
                          has been building a bridge between physical and
                          digital world in 7 Countries, more than 150+ Shopping
                          Malls, Universities and City Hospitals.
                        </p>
                      </>
                    )}

                    <article className="contact-form">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                          <input
                            type="text"
                            name="name"
                            placeholder="NAME"
                            value={this.state.name}
                            onChange={this.handleChange}
                          />

                          <input
                            type="text"
                            name="from"
                            placeholder="E-MAIL"
                            value={this.state.from}
                            onChange={this.handleChange}
                          />

                          <input
                            type="text"
                            name="phone"
                            placeholder="PHONE"
                            value={this.state.phone}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                          <textarea
                            name="message"
                            placeholder="MESSAGE"
                            value={this.state.message}
                            onChange={this.handleChange}
                          />

                          <div className="contact-term">
                            <article className="term">
                              <div className="checkbox-edit">
                                <input
                                  type="checkbox"
                                  name="agreement"
                                  id="checkbox-edit"
                                  onChange={this.handleChange}
                                  checked={this.state.agreement}
                                />
                                <label htmlFor="checkbox-edit" />
                              </div>

                              {/* <span>I accept the processing of my personal data </span> */}

                              {this.props.pageContext.locale == 'en' ? (
                                <span>
                                  I have read and agree to the
                                  <a
                                    data-toggle="modal"
                                    href="#exampleModalCenter"
                                    class="read-agreement read-newsletter-agreement"
                                  >
                                    {' '}
                                    terms
                                  </a>
                                  .
                                </span>
                              ) : (
                                <span>
                                  <a
                                    data-toggle="modal"
                                    href="#exampleModalCenter"
                                    class="read-agreement read-newsletter-agreement"
                                  >
                                    Şartları
                                  </a>{' '}
                                  okudum ve kabul ediyorum.
                                </span>
                              )}

                              <div className="w-send">
                                <a
                                  href="#"
                                  className="send-btn"
                                  onClick={this.sendmail}
                                >
                                  <FormattedMessage id="sendmessage" />
                                </a>
                              </div>
                            </article>
                          </div>

                          <p class="error-displayer" />
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div
                  class="modal fade"
                  id="exampleModalCenter"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <figure aria-hidden="true">&times;</figure>
                        </button>
                      </div>
                      <div class="modal-body" id="kvkk-body">
                        <h1>Kişisel Verilerin Korunması Aydınlatma Metni</h1>

                        <h2>Kişisel Verilerin Korunması</h2>

                        <p>
                          6698 sayılı Kişisel Verilerin Korunması Kanunu
                          ("KVKK") uyarınca, gerçek bir kişinin kimliğini
                          belirli ya da belirlenebilir bir hale getirmeye
                          yarayan her türlü bilgi kişisel veri kapsamındadır.
                          KNS Bilişim Teknolojileri Sanayi ve Ticaret Limited
                          Şirketi nezdindeki kişisel verileriniz aşağıda
                          açıklanan işlenme amaçları, hukuki nedenleri, toplanma
                          yöntemleri, kimlere ve hangi amaçlarla
                          aktarılabileceği ve gerek bunlara bağlı gerekse
                          bunlardan bağımsız olarak KVKK kapsamında size tanınan
                          haklara ilişkin olarak sizleri bilgilendirmek
                          istiyoruz.
                        </p>

                        <h3>
                          1. Kişisel verilerin toplanmasına ilişkin yöntemler ve
                          Hukuki Sebebi
                        </h3>

                        <p>
                          KNS Bilişim Teknolojileri tarafından veri sorumlusu
                          sıfatı ile internet sitesi, mobil uygulamalar, çağrı
                          merkezi, sosyal medya mecraları, doğrudan veya
                          yetkilendirilmiş iletişim kanalları ve fiziki kanallar
                          gibi farklı kanallarda icra edilen üretim ve tadilat
                          süreçlerindeki yöntemlerle; bu kanalları kullanarak
                          sizlere kaliteli hizmetlerimizi sunmak ve bu çerçevede
                          iş ve ticari faaliyetlerimizi yürütmek noktasında
                          uygulama imkanı bulan ilgili mevzuat, sözleşme, talep,
                          ticari teamül ve dürüstlük kurallarına uygun olarak
                          ortaya çıkan ve icra edilen sözlü, yazılı ya da
                          elektronik yöntemlerle kişisel verileriniz
                          toplanmaktadır.
                        </p>

                        <p>
                          Bu kapsamda kişisel verileriniz, KNS Bilişim
                          Teknolojileri veya KNS Bilişim Teknolojileri adına
                          veri işleyen gerçek ya da tüzel kişiler tarafından,
                          sayılanlarla sınırlı olmamak üzere, yazılı veya
                          elektronik olarak, aşağıda belirtilen yöntemlerle
                          toplanabilir;
                        </p>

                        <ul>
                          <li>
                            Elektronik ya da fiziki olarak doldurduğunuz üyelik
                            formu,
                          </li>
                          <li>İnternet sitelerimize üyelik,</li>
                          <li>
                            İnternet sitelerimiz üzerinde yer alan, bizimle
                            iletişime geçmek üzere doldurduğunuz iletişim
                            formları,
                          </li>
                          <li>
                            Sizi tanımak için kullanılan çerezler (cookies),
                            mobil uygulamalarımız,
                          </li>
                          <li>
                            Şirketimiz ile imzalamış olduğunuz muhtelif
                            sözleşmeler ile Şirketimize göndermiş olduğunuz her
                            türlü elektronik postalar, talepler, iş emirleri,
                            faks ve mektuplar,
                          </li>
                          <li>
                            Şirketimiz adına veri işleyen yahut üyelik programı
                            sürecinin herhangi bir safhasında şirketimize destek
                            veren üçüncü kişi firma(lar),
                          </li>
                          <li>
                            Çalışanlarımız, dijital pazarlama ve çağrı merkezi
                            dâhil olmak üzere müşteri hizmetleri kanallarımız,
                          </li>
                          <li>
                            Sosyal medya kanalları, Google vb. arama
                            motorlarının kullanımı,
                          </li>
                          <li>
                            Üyelik sözleşmeleri ve sair diğer sözleşmeler,
                            kampanyalar, başvurular, formlar, teklifler,
                          </li>
                        </ul>

                        <p>
                          Yukarıda anılan yöntemler uyarınca kişisel
                          verileriniz, tarafınızla sözleşme kurulması durumunda
                          sözleşmenin kurulması ve devamında sözleşmenin ifası
                          amacıyla gerekli olan verileriniz KVKK m.5/2(c)
                          bendinde yer alan "Bir sözleşmenin kurulması veya
                          ifasıyla doğrudan doğruya ilgili olması kaydıyla,
                          sözleşmenin taraflarına ait kişisel verilerin
                          işlenmesinin gerekli olması" istisnasına dayalı olarak
                          açık rızanız olmaksızın yalnızca işbu aydınlatma
                          metninde dayalı olarak işlenecektir.
                        </p>

                        <p>
                          Tarafınızla KNS Bilişim Teknolojileri arasında
                          sözleşmenin kurulması veya ifası amacıyla gerekli olan
                          kişisel verilerinizin haricindeki tüm kişisel
                          verileriniz ise işbu metni okumanızı müteakip açık
                          rıza vermeniz halinde açık rızanıza dayalı olarak
                          işlenecektir. Tarafınızla sözleşme kurulması sırasında
                          veya devamında sözleşmenin ifası için gerekli olan
                          kişisel verilerinizin haricindeki bu kişisel
                          verilerinizin işlenmesine açık rıza vermeme hakkınız
                          olduğunu, tarafınızla sözleşme kurulması veya ürün
                          satın almanız için bu açık rızanın bir ön şart
                          olmadığını belirtmek isteriz.
                        </p>
                        <h3>
                          2. Kişisel verilerin işlenmesi ve işleme amaçları
                        </h3>
                        <p>
                          KNS Bilişim Teknolojileri olarak, yukarıda sayılan
                          yöntemlerle toplanacak kişisel ve/veya özel nitelikli
                          kişisel verileriniz tamamen veya kısmen elde
                          edilebilir, kaydedilebilir, saklanabilir,
                          depolanabilir, değiştirilebilir, güncellenebilir,
                          periyodik olarak kontrol edilebilir, yeniden
                          düzenlenebilir, sınıflandırılabilir, işlendikleri amaç
                          için gerekli olan ya da ilgili kanunda öngörülen süre
                          kadar muhafaza edilebilir, aşağıda detaylı olarak
                          belirtilen 3.kişilerle paylaşılabilir/devredilebilir,
                          kanuni ya da hizmete bağlı fiili gereklilikler halinde
                          yurtdışına aktarılabilir.
                        </p>

                        <p>
                          KNS Bilişim Teknolojileri müşterilerinin
                          hizmetlerimizden faydalanabilmesi, ticari ve pazarlama
                          stratejilerimizi belirlemek, geliştirmek amacı ile
                          sizleri bilgilendirmek, öneri ve şikayetlerinizi kayıt
                          altına alabilmek, sizleri daha iyi tanıyarak
                          ihtiyaçlarınız doğrultusunda hizmet vermek, satış,
                          pazarlama, reklam ve tanıtım faaliyetlerimizin
                          yürütülmesinde öncülük etmeniz, satış ve pazarlama
                          faaliyetleri için yapılacak analizler ve profilleme
                          çalışmalarını yapabilmemiz, sizlere daha iyi hizmet
                          standartları oluşturabilmek, KNS Bilişim Teknolojileri
                          ticari ve iş stratejilerinin belirlenmesi ve
                          uygulanması gibi amaçlarla ve her halükarda 6698
                          sayılı Kişisel Verilerin Korunması Kanunu ve ilgili
                          mevzuata uygun olarak kişisel verilerinizi
                          işleyebileceğimizi bilginize sunarız.
                        </p>

                        <h3>
                          3.Kişisel Verilerin 3. Kişilere ve Yurtdışına
                          Aktarılması
                        </h3>

                        <p>
                          KNS Bilişim Teknolojileri, söz konusu kişisel
                          verilerinizi KVKK'da ve ilgili mevzuatta belirtilen
                          güvenlik ve gizlilik esasları çerçevesinde yeterli
                          önlemler alınmak kaydıyla yurt içinde ve gerekli
                          güvenlik önlemlerinin alınması kaydıyla yurt dışında,
                          Şirket faaliyetlerinin yürütülmesi, veri sahipleri ile
                          müşterilerimiz arasındaki iş ilişkisinin sağlanması
                          ve/veya bu amaçla görüşmeler yapılması, hizmetler,
                          fırsat ve olanaklar sunulması ve hizmet kalitesinin
                          artırılması amacıyla; yurt içinde ya da yurtdışında
                          bulunan sunucular ve bu sunucu desteğini veren
                          firmalar ile fiziksel server ve/veya bulut hizmeti
                          aldığımız yurt içi/yurt dışı kişi ve kurumlar, KNS
                          Bilişim Teknolojileri adına veri işleyen, müşteri
                          memnuniyeti ölçümleme, profilleme desteği veren, satış
                          ve pazarlama alanında kişisel verilerin işlenmesi
                          gereken konularda destek veren firmalar,
                          faaliyetlerimizin gereği anlaşmalı olduğumuz ve hizmet
                          sunduğumuz müşteriler, tedarikçiler, veya yasal bir
                          zorunluluk gereği bu verileri talep etmeye yetkili
                          olan kamu kurum veya kuruluşları, bunlarla sınırlı
                          olmamak üzere ilgili diğer otoriteler ile
                          paylaşabilecektir.
                        </p>

                        <h3>
                          4. Kişisel Veri Sahibinin KVK Kanunu'nun ("Kanun") 11.
                          maddesinde Sayılan Hakları
                        </h3>

                        <p>
                          KNS Bilişim Teknolojileri ilgili kişilerin aşağıdaki
                          taleplerine karşılık verecektir:
                        </p>

                        <ul>
                          <li>
                            KNS Bilişim Teknolojileri’nin kendilerine ilişkin
                            kişisel verileri işleyip işlemediğini ve hangi
                            kişisel verileri işlediğini öğrenme,
                          </li>
                          <li>
                            İşleme faaliyetinin amaçlarına ilişkin bilgi alma,
                          </li>
                          <li>
                            KNS Bilişim Teknolojileri’nin yurt içinde veya yurt
                            dışında kişisel verileri aktardığı üçüncü kişileri
                            bilme,
                          </li>
                          <li>
                            Kişisel verilerin eksik veya yanlış işlenmiş olması
                            hâlinde bunların düzeltilmesini isteme,
                          </li>
                          <li>
                            Kanun'a uygun olarak kişisel verilerin silinmesini
                            veya yok edilmesini isteme,
                          </li>
                          <li>
                            Kişisel verilerin düzeltilmesi, silinmesi ya da yok
                            edilmesi talebi halinde; yapılan işlemlerin, kişisel
                            verilerin aktarıldığı üçüncü kişilere bildirilmesini
                            isteme,
                          </li>
                          <li>
                            İşlenen verilerin münhasıran otomatik sistemler
                            vasıtasıyla analiz edilmesi suretiyle kişinin
                            kendisi aleyhine bir sonucun ortaya çıkmasına itiraz
                            etme ve
                          </li>
                          <li>Kişisel verilerinin birer kopyasını alma.</li>
                        </ul>
                        <p>
                          Görüş ve sorularınızla ilgili bizimle iletişime
                          geçebilirsiniz.
                        </p>
                        <p>
                          Unvan: KNS Bilişim Teknolojileri Sanayi ve Ticaret
                          Limited Şirketi
                        </p>
                        <p>E-mail adresi: info@kns.com.tr</p>
                        <p>
                          Posta adresi: Papirus Plaza, Merkez Mahallesi Ayazma Caddesi No:37/132, Kat:7 Ofis No: 4, 34406 Kâğıthane/İstanbul, Turkey
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </body>
      </Layout>
    )
  }
}
ContactPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export default ContactPage
